export const GAME_ID = 99
export const PUBLIC_API_URL = 'https://api.test.mod.io/v1'
export const PUBLIC_API_KEY = 'c596a75e419d93c4e69cdcfa304ab25a'
export const PRIMARY_COLOR = '#689c80'
export const DARK_COLOR = '#141514'
export const LIGHT_COLOR = '#ffffff'
export const DARK_MODE = true
export const TAG_FILTER = null
export const showLegal = false
export const hostURL = '*'
